<template>
  <div class="container">
    <PublicHeader :totalName="$t('safeCenter').setPayPwd" />
    <div class="name">{{ $t('safeCenter').payPassMsg }}</div>
    <div class="txt">{{ $t('safeCenter').payPassMsg2 }}</div>
    <van-password-input :value="Password" :length="6" :gutter="10" :focused="showKeyboard"
      @focus="showKeyboard = true" />
    <!-- 按钮 -->
    <div class="btn">
      <van-button @click="submitPsw" color="#C92B2E" block style="border-radius: 5px;height: 40px;">{{
        $t('safeCenter').setBtn }}</van-button>
    </div>
    <!-- 数字键盘 -->
    <van-number-keyboard v-model="Password" :show="showKeyboard" @blur="showKeyboard = false" />
  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      Password: '',//密码
      showKeyboard: true,
    }
  },
  watch: {
    Password() {
      if (this.Password.length == 6)
        this.showKeyboard = false
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
  },
  methods: {
    async submitPsw() {
      if (this.Password.length < 6) {
        this.$toast(this.$t('safeCenter').payPassMsg)
        return
      }
      this.Password = this.Password.slice(0, 6);
      const form = {
        Str: this.Password,
      }
      await userApi.SetFundPassword(form)
      this.$toast({ message: this.$t('safeCenter').setSuccess })
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 120px 15px 0 15px;
  background: #FFFFFF;

  .name {
    text-align: center;
    font-weight: 800;
    font-size: 17px;
    color: #392F2F;
  }

  .txt {
    text-align: center;
    margin: 10px 0 50px 0;
    font-size: 13px;
    color: #999999;
  }

  .btn {
    margin: 100px 15px 0 15px;
  }

}

::v-deep .van-password-input__security {
  height: 58px;
}

::v-deep .van-password-input__security li {
  background: #E8E8E8;
  border-radius: 5px;
}
</style>
